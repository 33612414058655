import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Trans } from 'react-i18next';
import BonusSelector from '@/components/organisms/BonusSelector';
import ChangeBonusButton from '@/components/atoms/Buttons/ChangeBonus';
import Container from '@/components/atoms/Container/styles';
import DeskContainer from '@/components/atoms/DeskContainer/styles';
import Loading from '@/components/atoms/Loading';
import MerchantIcon from '@/components/atoms/MerchantIcon';
import NavMenuLeft from '@/components/organisms/NavMenuLeft';
import PointsMultiplierSummary from '@/components/atoms/PointsMultiplierSummary';
import SelectDefault from '@/components/atoms/SelectDefault';
import TransactionPreview from '@/components/molecules/TransactionPreview';
import TransferForm from '@/components/organisms/TransferForm';
import WalletHeader from '@/components/molecules/WalletHeader';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { calculateBonus, filterMerchantHasBonuses } from '@/helpers/functions';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import {
  DeskWrapper,
  HideInDesktop,
} from '@/components/atoms/GenerateTransactionStyle/styles';

import TwTitle from '@/components/atoms/TwTitle';
import useMerchants from '@/hooks/useMerchants';
import useUsernames from '@/hooks/useUsernames';
import AffiliationStatus from '@/components/atoms/AffiliationStatus';

function Transfer({ match }) {
  const { isMobileLayout } = useDeviceWidth();
  const history = useHistory();
  const { merchantId } = match.params;

  const [showDetails, setShowDetails] = useState(false);
  const [merchantCurrency, setMerchantCurrency] = useState('BRL');
  const queryParams = new URLSearchParams(history.location.search);
  const { loadingMerchants, getMoreData } = useMerchants();
  const { loadingUsernames } = useUsernames();
  const {
    setWalletData,
    walletData: {
      merchants,
      selectedMerchant,
      selectedBonus,
      transactionData,
      transactionSubmitError,
    },
  } = useWalletData();

  const { userData } = useUserData();
  const { bonuses, usernames } = userData;

  useEffect(() => {
    getMoreData();
    if (!match.params.merchantId) {
      history.push('/transfer');
    }
    if (match.params.merchantId && !selectedMerchant?.id) {
      const merchant = merchants.find(
        (el) => el.id === Number(match.params.merchantId),
      );
      setWalletData((prevState) => ({
        ...prevState,
        selectedMerchant: merchant,
      }));
    }
  }, [merchants, match.params.merchantId]);

  useEffect(() => {
    if (!isMobileLayout) {
      history.push('/transfer');
    }

    if (queryParams.get('b')) {
      const bonus = bonuses.find(
        (el) => el.id === Number(queryParams.get('b')),
      );
      setWalletData((prevState) => ({
        ...prevState,
        selectedBonus: bonus,
      }));
    }
  }, [isMobileLayout]);

  const transferBonuses = bonuses?.filter(
    ({ transaction_type }) => transaction_type === 'transfer-in',
  );
  const merchantBonuses = filterMerchantHasBonuses(
    transferBonuses,
    selectedMerchant?.id,
  );

  const bonusAmount = calculateBonus(transactionData?.amount, selectedBonus);
  const amountWithBonus = Number(transactionData.amount) + Number(bonusAmount);

  const affiliation = usernames?.find(
    ({ merchant_id }) => merchant_id === selectedMerchant?.id,
  );

  useEffect(
    () => () => {
      setWalletData((prevState) => ({
        ...prevState,
        selectedBonus: null,
        transactionData: { amount: null },
        filteredMerchantsByBonus: [],
        transactionSubmitError: false,
      }));
    },
    [setWalletData],
  );

  useEffect(() => {
    setMerchantCurrency(selectedMerchant?.currencies?.[0]);
  }, [selectedMerchant]);

  const hasMerchantSelected = selectedMerchant && !loadingMerchants;
  const shouldDisplayTransfer =
    !merchantBonuses?.length ||
    selectedBonus?.id ||
    selectedBonus === 'default' ||
    !isMobileLayout;
  return (
    <DeskContainer>
      <NavMenuLeft />
      <Container gradientLight>
        <WalletHeader
          title={i18n.t('transfer.titleMobile')}
          showAvailableBalance
          returnAction={
            transactionSubmitError
              ? () =>
                  setWalletData((prevState) => ({
                    ...prevState,
                    transactionSubmitError: false,
                  }))
              : showDetails
              ? () => setShowDetails(false)
              : history.goBack
          }
          returnTo={transactionSubmitError ? null : '/transfer'}
        />
        <DeskWrapper>
          <section className="flex flex-col items-center justify-center relative w-[90%] h-full">
            {(!merchantBonuses?.length || selectedBonus) &&
              (!showDetails || transactionSubmitError) && (
                <HideInDesktop>
                  {transactionSubmitError ? (
                    <TwTitle
                      size={isMobileLayout ? 'm_lg' : 'd_lg'}
                      font="arboriaBold"
                      classList="text-center uppercase"
                    >
                      <Trans
                        i18nKey={i18n.t('transfer.errorTitle')}
                        i18n={i18n}
                        components={[<strong />]}
                      />
                    </TwTitle>
                  ) : (
                    <TwTitle
                      size={isMobileLayout ? 'mx_lg' : 'd_lg'}
                      font="arboriaBold"
                      classList="text-center"
                    >
                      {i18n.t('transfer.title')}{' '}
                      <span>{selectedMerchant?.name}</span>
                    </TwTitle>
                  )}
                  <MerchantIcon
                    imageUrl={selectedMerchant?.image_url}
                    width="55px"
                  />
                  {!loadingUsernames && (
                    <AffiliationStatus data={affiliation} userData={userData} />
                  )}
                  <SelectDefault
                    options={selectedMerchant?.currencies}
                    onChange={({ target }) => setMerchantCurrency(target.value)}
                    disabled={showDetails}
                  />
                  {selectedMerchant?.transferin_multiplier_points > 1 &&
                    selectedBonus && (
                      <PointsMultiplierSummary merchant={selectedMerchant} />
                    )}
                </HideInDesktop>
              )}
            {selectedBonus && !showDetails && (
              <ChangeBonusButton
                color="secondary"
                type="transfer-in"
                customAction={
                  queryParams.get('b')
                    ? () => history.push('/bonuses-and-promotions')
                    : null
                }
              />
            )}
            {showDetails && (!transactionSubmitError || !isMobileLayout) && (
              <TransactionPreview type="transfer" />
            )}
            {shouldDisplayTransfer && hasMerchantSelected ? (
              <TransferForm
                merchant={selectedMerchant}
                merchantId={merchantId}
                usernameOnMerchant={affiliation?.username}
                isFirstTransfer={
                  !affiliation?.username && selectedMerchant?.slug
                }
                bonus={selectedBonus ?? {}}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
                finalAmount={amountWithBonus}
                merchantCurrency={merchantCurrency}
              />
            ) : !selectedBonus && merchantBonuses?.length && isMobileLayout ? (
              <BonusSelector data={merchantBonuses} />
            ) : (
              <Loading marginTop="150px" />
            )}
          </section>
        </DeskWrapper>
      </Container>
    </DeskContainer>
  );
}

Transfer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantId: PropTypes.string,
    }),
  }),
}.isRequired;

export default Transfer;
