import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MenuModal from '@/components/organisms/MenuModal';
import ProfileMenuModal from '@/components/organisms/ProfileMenuModal';
import { ReactComponent as MenuIcon } from '@/assets/menuButton.svg';
import { ReactComponent as UserIcon } from '@/assets/profileButton.svg';

import { Logo, Wrapper } from './styles';
import NotificationsButton from '@/components/v2/atoms/NotificationsButton';
import { useNotifications } from '@/hooks/useNotifications';

function TopBar({ showInMobile }) {
  const [showMenu, setShowMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { unreadNotifications } = useNotifications();

  return (
    <Wrapper showInMobile={showInMobile}>
      <MenuIcon onClick={() => setShowMenu(true)} />
      <Logo />
      {false && <NotificationsButton unread={unreadNotifications} />}
      <UserIcon onClick={() => setShowProfileMenu(true)} />
      <MenuModal showMenu={showMenu} setShowMenu={setShowMenu} />
      <ProfileMenuModal
        showMenu={showProfileMenu}
        setShowMenu={setShowProfileMenu}
      />
    </Wrapper>
  );
}

TopBar.propTypes = {
  showInMobile: PropTypes.bool,
}.isRequired;

export default TopBar;
