import styled, { css } from 'styled-components';
import { Paragraph } from '../Typography/styles';

export const OptionCashBackContainer = styled.div`
  align-items: center;
  background-color: ${({ vpag, theme }) =>
    vpag ? theme.colors.greenVpag : theme.colors.blue};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 115px;
  justify-content: center;
  max-height: 115px;
  max-width: 146px;
  padding: 6px;
  transition: opacity 0.2s;
  width: 146px;
  z-index: 1;

  :hover {
    opacity: 0.8;
  }
`;

export const ParagraphStyled = styled(Paragraph)`
  ${({ theme }) => css`
    color: ${theme.colors.sidebar.bg};
    font-weight: 800;
    line-height: 22.66px;
    text-align: center;
  `};
`;
