/* eslint-disable react/no-danger */
import React from 'react';
import { useHistory } from 'react-router-dom';
import i18n from '@/translate/i18n';

const SITE_NAME = 'vpag.com';

const NotificationModal = ({ closeModal, content }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    closeModal();
  };
  const history = useHistory();
  const actualPage = window.location.pathname;
  const isExternalCta = !content?.cta_url?.includes(SITE_NAME);
  const isExternalLink = !content?.secondary_link_url?.includes(SITE_NAME);

  const handleRedirect = (link, isExternal) => {
    if (isExternal) {
      window.open(link, '_blank');
    } else {
      const path = link.replace(/^https?:\/\/[^/]+/, '');
      history.push({
        pathname: path,
        from: actualPage,
      });
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div className="relative bg-white max-h-[90%] overflow-y-auto rounded w-full max-w-[600px] text-black text-left">
        <button
          type="button"
          className="flex items-center justify-center absolute pb-[0.3rem] h-8 w-8 top-2 right-2 text-white rounded-full cursor-pointer text-3xl bg-[#343a40] leading-none"
          onClick={handleClick}
        >
          &times;
        </button>

        {content.image_path && (
          <img
            className="w-full"
            src={content.image_path}
            alt="notification_image"
          />
        )}
        <div className="p-10 w-full">
          <h1 className="text-lg font-bold mb-5">{content.subject}</h1>
          <div
            className="leading-loose"
            dangerouslySetInnerHTML={{ __html: content.description }}
          />
          <div
            className={`w-100 ${
              content.cta_text || content.secondary_link_text
                ? 'my-[30px]'
                : 'my-0'
            } mx-0`}
          >
            {content.cta_text && (
              <button
                type="button"
                className="text-white bg-black text-sm font-bold rounded block mx-auto py-[10px] px-10 w-fit"
                onClick={(e) => {
                  handleRedirect(content.cta_url, isExternalCta);
                  e.preventDefault();
                }}
              >
                {content.cta_text}
              </button>
            )}

            {content.secondary_link_text && (
              <button
                type="button"
                className="font-light text-black underline w-fit m-2 mx-auto block"
                onClick={(e) => {
                  handleRedirect(content.secondary_link_url, isExternalLink);
                  e.preventDefault();
                }}
              >
                {content.secondary_link_text}
              </button>
            )}
          </div>
          <div className="mt-3 mb-1">
            {i18n.t('notification.messages.signature')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
