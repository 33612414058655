import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import BankIcon from '@/components/atoms/BankIcon';
import { hideAccountNumber } from '@/helpers/stringFormat';

function DeleteBankAccount({ setShowModal, account }) {
  const { slug, bank, accountNumber, dv } = account;

  return (
    <section className="flex flex-col items-center justify-evenly h-[324px] w-[90%]">
      <h1 className="text-white text-[1.125rem] font-normal leading-[27px] mt-5 text-center">
        {i18n.t('deleteBankAccount.title')}
      </h1>
      <div className="flex items-center justify-center w-[70%]">
        <BankIcon slug={slug} width="26px" />
        <div className="flex flex-col">
          <p className="text-white text-xs ml-[15px]">
            <strong>{bank}</strong>
          </p>
          <p className="text-white text-xs ml-[15px]">
            {hideAccountNumber(`${accountNumber}-${dv}`)}
          </p>
        </div>
      </div>

      <div className="flex justify-between w-[90%]">
        <button
          className="bg-transparent border border-grass-800 text-grass-800 font-arboria-regular text-[1.125rem] h-10 max-w-[140px] py-[7px] w-[35vw] rounded-[3px]"
          type="button"
        >
          {i18n.t('deleteBankAccount.yes')}
        </button>
        <button
          className="bg-transparent border border-grass-800 text-grass-800 font-arboria-regular text-[1.125rem] h-10 max-w-[140px] py-[7px] w-[35vw] rounded-[3px]"
          type="button"
          onClick={() => setShowModal(false)}
        >
          {i18n.t('deleteBankAccount.no')}
        </button>
      </div>
    </section>
  );
}

DeleteBankAccount.propTypes = {
  setShowModal: PropTypes.bool,
}.isRequired;

export default DeleteBankAccount;
