import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import MainTemplate from '@/components/templates/MainTemplate';
import ProfileHeader from '@/components/molecules/ProfileHeader';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import i18n from '@/translate/i18n';

function ProfileTemplate({ children }) {
  const history = useHistory();
  return (
    <MainTemplate
      containerProps={{ gradientLight: true }}
      headerProps={{
        title: i18n.t('profile.title'),
        returnAction: () => history.push('/profile'),
      }}
    >
      <div className="flex flex-col !bg-red items-center justify-evenly overflow-x-hidden w-full flex-1 md:grid md:items-center md:overflow-visible md:p-[4vh_0] md:grid-cols-[47%_1%_47%]">
        <ProfileHeader hideInMobile />
        <SectionDivider breakpoint="1024px" />
        <section className="flex items-center justify-center h-full w-full">
          {children}
        </section>
      </div>
    </MainTemplate>
  );
}

ProfileTemplate.propTypes = {
  children: PropTypes.element,
}.isRequired;

export default ProfileTemplate;
