import React from 'react';
import TwTitle from '@/components/atoms/TwTitle';
import TwText from '@/components/v2/atoms/TwText';
import i18n from '@/translate/i18n';

const SlideContentThreeApresentation = () => (
  <div className="h-full flex flex-col justify-start">
    <div className="iphone-5:mt-[10px] max-h-780:mt-5 mt-[124px] max-h-780:gap-2 gap-4 flex flex-col">
      <TwTitle
        classList="max-h-780:text-3xl text-[35px] !font-normal leading-[2.125rem]"
        font="arboriaRegular"
      >
        {i18n.t('apresentationScreen.with')}
        <span className="text-grass-800">
          {i18n.t('apresentationScreen.vpag')}
        </span>
        {i18n.t('apresentationScreen.youHaveAdvantage')}
      </TwTitle>
      <TwText
        classList="w-[292px] mx-auto max-h-780:!text-base !text-[18px] font-normal leading-[21px] !text-center"
        text={i18n.t('apresentationScreen.EarnPointsWithEveryTransfer')}
      />
    </div>
  </div>
);

export default SlideContentThreeApresentation;
