import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import {
  checkIsAuthenticated,
  getUserInsiteNotifications,
  getUserUnreadNotifications,
  listMerchants,
  updateInsiteNotificationRead,
  updateInsiteNotificationReadAll,
} from '@/services/api';
import i18n from '@/translate/i18n';

export const NotificationsContext = React.createContext({});

const defaultState = {
  notifications: [],
  page: 1,
  pageCount: 0,
};

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 7;
const DEFAULT_ONLY_NEWS = false;
const NOTIFICATION_TYPES = {
  validationApproved: 'customer-validation-approved',
  identityPending: 'customer-identity-pending',
  affiliations: 'affiliation',
  admin: 'admin',
};

export default function NotificationsProvider({ children }) {
  const [notificationsData, setNotificationsData] = useState(defaultState);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const controller = new AbortController();
  const getUserNotifications = async (
    onlyNews = DEFAULT_ONLY_NEWS,
    perPage = DEFAULT_PER_PAGE,
    page = DEFAULT_PAGE,
  ) => {
    try {
      const params = {
        ...(onlyNews && { read_notification: 0 }),
        order: 'desc',
        order_by: 'id',
        page,
        per_page: perPage,
      };
      const merchantIds = new Set();
      const notificationRes = await getUserInsiteNotifications(
        params,
        controller.signal,
      );

      if (notificationRes.data.length) {
        notificationRes.data.forEach((item) => {
          if (item.data?.merchantId) {
            merchantIds.add(item.data.merchantId);
          }
        });
      }
      const merchantIdsPayload = Array.from(merchantIds);
      let merchants = [];
      if (merchantIdsPayload.length && notificationRes.data.length) {
        merchants = await listMerchants({
          id: merchantIdsPayload,
        }).then((merchRes) => merchRes.data);
      }
      if (merchants.length) {
        notificationRes.data = notificationRes.data.map((item) =>
          item.data?.merchantId
            ? {
                ...item,
                data: {
                  ...item.data,
                  merchant_name: merchants.find(
                    (merch) => merch.id === item.data.merchantId,
                  )?.name,
                },
              }
            : item,
        );
      }
      setNotificationsData((state) => ({
        ...state,
        notifications: notificationRes.data,
        pageCount: Math.ceil(
          notificationRes.meta.total / notificationRes.meta.per_page,
        ),
      }));
    } catch (err) {
      toast.error(i18n.t('notifications.messages.notifications-error'));
    }
  };

  const getUnreadNotifications = async () => {
    try {
      const res = await getUserUnreadNotifications(controller.signal);
      setUnreadNotifications(res?.data?.unread_notifications || 0);
    } catch (err) {
      toast.error(i18n.t('notifications.messages.notifications-error'));
    }
  };

  const updateNotificationReaded = async (id) => {
    try {
      setNotificationsData((state) => ({
        notifications: state.notifications.map((item) =>
          item.id === id
            ? { ...item, read_at: new Date().toISOString() }
            : item,
        ),
      }));
      await updateInsiteNotificationRead([id], controller.signal);
      setUnreadNotifications((state) => (state > 0 ? state - 1 : state));
    } catch (err) {
      setNotificationsData((state) => ({
        notifications: state.notifications.map((item) =>
          item.id === id ? { ...item, read_at: null } : item,
        ),
      }));
      setUnreadNotifications((state) => state + 1);
    }
  };

  const updateNotificationReadedAll = async () => {
    const today = new Date().toISOString();
    try {
      await updateInsiteNotificationReadAll(controller.signal);
      setNotificationsData((state) => ({
        notifications: state.notifications.map((item) => ({
          ...item,
          read_at: today,
        })),
        pageCount: state.pageCount,
        page: DEFAULT_PAGE,
      }));
      setUnreadNotifications(0);
    } catch (err) {
      toast.error(i18n.t('notifications.messages.notifications-error'));
    }
  };

  const changeNotificationPage = (queries) => {
    setNotificationsData((state) => ({
      ...state,
      page: queries.page,
    }));
    getUserNotifications(queries.onlyNews, queries.perPage, queries.page);
  };

  useEffect(
    () => () => {
      controller.abort();
    },
    [],
  );

  useEffect(() => {
    const getNotifications = async () => {
      const response = await checkIsAuthenticated();
      if (response) {
        getUnreadNotifications();
      }
    };

    getNotifications();
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notificationsData,
        unreadNotifications,
        changeNotificationPage,
        setNotificationsData,
        getUserNotifications,
        getUnreadNotifications,
        updateNotificationReaded,
        updateNotificationReadedAll,
        NOTIFICATION_TYPES,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
