import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: ${({ hideInMobile }) => (hideInMobile ? 'none' : 'flex')};
  flex-direction: column;
  gap: 1.5rem;

  & > h1 {
    display: none;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    height: 100%;
    justify-content: space-between;
    max-height: 550px;
    min-width: 300px;

    & > h1 {
      display: inline;
    }
  }
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
`;

export const UploadButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 85px;
  z-index: 3;
`;

export const UserProfilePic = styled.img`
  border-radius: 50%;
  height: 120px;
  object-fit: cover;
  width: 120px;
`;

export const ActionBtnWrapper = styled.div`
  display: none;
  margin-top: 1.5rem;

  button {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    font: ${({ theme }) =>
      `${theme.font.size.xSmall} ${theme.font.family.secondary}`};
    margin: 0 3px;
    padding: 2px 3px;
    text-transform: uppercase;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;
