import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Trans } from 'react-i18next';

import Tooltip from '@/components/atoms/Tooltip';
import { Paragraph } from '@/components/atoms/Typography/styles';
import Modal from '@/components/molecules/Modal';
import { useWalletData } from '@/context/walletContext';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import i18n from '@/translate/i18n';

import Wrapper from './styles';
import { formatCpf } from '@/helpers/stringFormat';

const ICON_SIZE = 15;

function AffiliationStatus({ data, userData }) {
  const {
    walletData: { transactionData },
  } = useWalletData();
  const [showModal, setShowModal] = useState(false);
  const { isMobileLayout } = useDeviceWidth();

  const textColor = isMobileLayout ? 'light' : 'dark';

  const tooltipContent = (
    <Paragraph
      color={textColor}
      highlightColor={textColor}
      padding={isMobileLayout ? '35px' : '0'}
    >
      <Trans
        i18n={i18n}
        i18nKey={`affiliationStatus.${data?.affiliate_status}`}
        components={[<span />, <br />]}
      />
    </Paragraph>
  );

  return (
    <>
      <Tooltip
        content={tooltipContent}
        disabled={isMobileLayout || !data?.username}
      >
        <Wrapper status={data?.affiliate_status} onClick={setShowModal}>
          {(data?.username || transactionData?.username) && (
            <p>
              CPF:{' '}
              <span id="affiliation-username">{formatCpf(userData?.cpf)}</span>
            </p>
          )}
          {data?.username && <AiOutlineInfoCircle size={ICON_SIZE} />}
        </Wrapper>
      </Tooltip>
      <Modal
        showModal={showModal && isMobileLayout}
        setShowModal={setShowModal}
        height="100px"
      >
        {tooltipContent}
      </Modal>
    </>
  );
}

AffiliationStatus.propTypes = {
  data: PropTypes.shape({
    username: PropTypes.string,
  }),
  merchantName: PropTypes.string,
  newUsername: PropTypes.string,
  userData: PropTypes.shape({
    cpf: PropTypes.string,
  }),
}.isRequired;

export default AffiliationStatus;
