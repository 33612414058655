import styled from 'styled-components';

export const ContainerInputCopy = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 0 20px;

  button {
    background-color: transparent;
    border: none;
    height: fit-content;
    width: fit-content;

    :hover {
      opacity: 0.5;
    }
  }
`;
