import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import SelectRounded from '../SelectRounded';
import { useWalletData } from '@/context/walletContext';
import { Section, SelectedBox, Text, Wrapper } from './styles';

const OPT_MIN_LENGTH = 1;
const DECIMAL_PLACES = 2;

function ExchangeRate({ rate, handleChange }) {
  const { walletData } = useWalletData();
  const { selectedWallet, wallets } = walletData;

  const options = Object.keys(wallets).filter((el) => el !== selectedWallet);

  return (
    <Wrapper>
      <Section>
        <Text>{i18n.t('conversion.convertTo')}</Text>
        {options?.length > OPT_MIN_LENGTH ? (
          <SelectRounded
            options={options}
            onChange={handleChange}
            id="conversion-currency-selector"
          />
        ) : (
          <SelectedBox id="conversion-currency">{options[0]}</SelectedBox>
        )}
      </Section>
      <p>
        {i18n.t('conversion.exchangeRate')}
        <span className="text-grass-800 ml-[5px]" id="exchange-rate">
          {rate && rate.toFixed(DECIMAL_PLACES)}
        </span>
      </p>
    </Wrapper>
  );
}

ExchangeRate.propTypes = {
  handleChange: PropTypes.func,
  rate: PropTypes.number,
}.isRequired;

export default ExchangeRate;
