import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import InputTransactionValue from '@/components/atoms/Inputs/InputTransactionValue';
import LimitInstructions from '@/components/molecules/Modal/Messages/LimitInstructions';
import Loading from '@/components/atoms/Loading';
import AffiliationForm from '@/components/organisms/AffiliationForm';
import Modal from '@/components/molecules/Modal';
import TransferButton from '@/components/atoms/Buttons/TransferButton';
import WarningMessage from '@/components/molecules/WarningMessage';
import { ButtonWrapper } from '@/components/atoms/Buttons/TransactionButton/styles';
import { ErrorText } from '@/components/atoms/GenerateTransactionStyle/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import { registrationStatus } from '@/helpers/constants';
import useBalance from '@/hooks/useBalance';
import useExchangeRate from '@/hooks/useExchangeRate';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useUserBonuses from '@/hooks/useUserBonuses';
import { calculateBonus } from '@/helpers/functions';
import useTransactionLimits from '@/hooks/useTransactionLimits';
import { createTransferIn } from '@/services/api';
import i18n from '@/translate/i18n';

import { AnimationWrapper, FormStyled, InputWrapper } from './styles';

import TwToggleSwitch from '@/components/v2/atoms/TwToggleSwitch';

const DECIMAL_PLACES = 2;

function TransferForm({
  isFetching,
  merchant,
  usernameOnMerchant,
  setShowDetails,
  showDetails,
  finalAmount,
  merchantCurrency,
}) {
  const history = useHistory();
  const isProcessing = useRef(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [isMerchantLimitError, setIsMerchantLimitError] = useState(false);
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [bonusConsent, setBonusConsent] = useState(false);
  const { isMobileLayout } = useDeviceWidth();
  const { userData } = useUserData();
  const { getBonuses } = useUserBonuses();
  const { getBalance } = useBalance();
  const { getExchangeData, isLoadingExchangeRate } = useExchangeRate(
    'BRL',
    merchantCurrency,
  );

  const {
    setIsLoading,
    setWalletData,
    walletBalance,
    walletData: {
      selectedWallet,
      selectedBonus,
      selectedMerchant,
      transactionData,
      transactionSubmitError,
    },
  } = useWalletData();

  const { amount, exchangeRates } = transactionData;
  const transactionHasBonus =
    selectedBonus && typeof selectedBonus === 'object';

  const { limitsError, loadingLimits, transactionLimits } =
    useTransactionLimits(
      'transfer-in',
      selectedWallet,
      null,
      selectedMerchant?.id,
    );

  const { max_amount_per_transaction: maxValue } = transactionLimits;

  const insufficientFund = amount > walletBalance;

  const userHasPendingValidations = !userData?.kyc_level;
  const identityValidationPending =
    userData?.status === registrationStatus.validateId;

  const createTransfer = async (payload) => {
    try {
      isProcessing.current = true;
      setIsLoading(true);
      setIsMerchantLimitError(false);
      const res = await createTransferIn(payload, null);
      const { id } = res.data;

      await getBonuses();
      await getBalance();
      history.push(`/transactions/${id}`, { from: 'transfer-in-page' });
    } catch (err) {
      const message = err?.response?.data?.message;
      if (err?.response?.data?.data?.error === 'max-merchant-amount') {
        setIsMerchantLimitError(true);
      }
      setWalletData((prevState) => ({
        ...prevState,
        transactionSubmitError: true,
      }));
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
      isProcessing.current = false;
    }
  };

  useEffect(() => {
    setWalletData((prevState) => ({
      ...prevState,
      transactionData: {
        ...prevState?.transactionData,
        username: '',
      },
    }));
  }, [selectedMerchant]);

  useEffect(() => {
    const hasDividendRate = exchangeRates?.[`BRL-${merchantCurrency}`];

    if (showDetails && merchantCurrency !== 'BRL' && !hasDividendRate) {
      getExchangeData();
    }
  }, [showDetails]);

  const dividendRate = exchangeRates?.[`BRL-${merchantCurrency}`];
  const convertedValue =
    merchantCurrency !== 'BRL' && showDetails
      ? (finalAmount * dividendRate).toFixed(DECIMAL_PLACES)
      : null;

  const merchantHasNewIntegration = merchant.new_integration;

  return (
    <>
      {!isFetching && !loadingLimits && !limitsError ? (
        <>
          {!usernameOnMerchant &&
          !merchantHasNewIntegration &&
          !transactionData?.username &&
          !showDetails &&
          !userHasPendingValidations ? (
            <AnimationWrapper>
              <AffiliationForm merchant={selectedMerchant} />
            </AnimationWrapper>
          ) : (
            <Formik
              initialValues={{
                newUsername: '',
                merchant_currency_iso:
                  merchantCurrency ??
                  selectedMerchant?.currencies?.[0] ??
                  'BRL',
              }}
              onSubmit={async ({ merchant_currency_iso }) => {
                if (isProcessing.current) return;
                const userNameData =
                  !merchantHasNewIntegration && usernameOnMerchant
                    ? { username: usernameOnMerchant }
                    : {};

                setWalletData((state) => ({
                  ...state,
                  transactionData: {
                    ...state.transactionData,
                    customer_currency_iso: selectedWallet,
                    merchant_currency_iso:
                      merchantCurrency ?? merchant_currency_iso,
                    merchant_id: merchant.id,
                    merchant_name: merchant.name,
                    merchant_slug: merchant.slug,
                    type: 'transfer-in',
                    ...userNameData,
                  },
                }));
                if (setShowDetails && !showDetails) {
                  setShowDetails(true);
                } else {
                  const userNameRequest = !merchantHasNewIntegration
                    ? { username: transactionData?.username }
                    : {};
                  const payload = {
                    amount: transactionData.amount,
                    customer_currency_iso:
                      transactionData?.customer_currency_iso,
                    merchant_currency_iso:
                      transactionData?.merchant_currency_iso,
                    merchant_id: transactionData?.merchant_id,
                    merchant_slug: transactionData?.merchant_slug,
                    ...userNameRequest,
                    ...(selectedBonus?.id && {
                      bonus_id: selectedBonus.id,
                      bonus_amount: calculateBonus(amount, selectedBonus),
                      amount: Number(transactionData.amount),
                    }),
                  };

                  await createTransfer(payload);
                }
              }}
            >
              {({ isValid }) => {
                const canTransferWithoutDetails = !showDetails && isValid;
                const bonusConsentWithoutTransactionBonus =
                  bonusConsent || !transactionHasBonus;
                const canTransferWithDetailsAndBonus =
                  showDetails && isValid && bonusConsentWithoutTransactionBonus;
                return (
                  <FormStyled>
                    <AnimationWrapper>
                      {transactionSubmitError && (
                        <p>
                          {i18n.t([
                            `error.transfer.${errorMessage}`,
                            'error.transfer.unspecific',
                          ])}
                        </p>
                      )}
                      {isMobileLayout &&
                        showDetails &&
                        transactionHasBonus &&
                        !transactionSubmitError && (
                          <div className="flex relative mb-10 items-center justify-center">
                            <TwToggleSwitch
                              onClick={(e) => {
                                setBonusConsent(e.target.checked);
                              }}
                              checked={bonusConsent}
                              type="standard"
                            />
                            <p className="ml-5 max-w-64 text-left font-arboria-regular text-sand-800 text-sm">
                              {i18n.t('transfer.consentMsg')}
                              {selectedMerchant?.name}
                            </p>
                          </div>
                        )}
                      {Boolean(
                        (merchantHasNewIntegration ||
                          usernameOnMerchant ||
                          transactionData?.username) &&
                          !(showDetails && isMobileLayout),
                      ) && (
                        <InputWrapper>
                          <InputTransactionValue
                            content={
                              transactionSubmitError ? (
                                <Trans
                                  i18nKey="transfer.messageOnError"
                                  i18n={i18n}
                                  components={[<span />]}
                                  tOptions={{
                                    merchant: selectedMerchant?.name,
                                  }}
                                />
                              ) : showDetails ? (
                                <Trans
                                  i18nKey="transfer.youWillReceive"
                                  i18n={i18n}
                                  components={[<span />]}
                                  tOptions={{
                                    merchant: selectedMerchant?.name,
                                  }}
                                />
                              ) : (
                                i18n.t('transfer.youWillTransfer')
                              )
                            }
                            insufficientFund={insufficientFund}
                            disabled={showDetails}
                            value={
                              showDetails && merchantCurrency !== 'BRL'
                                ? convertedValue
                                : showDetails && finalAmount
                                ? finalAmount
                                : null
                            }
                            currency={
                              showDetails && merchantCurrency
                                ? merchantCurrency
                                : null
                            }
                            isError={transactionSubmitError}
                            transactionType="transferIn"
                            loading={isLoadingExchangeRate}
                            titleSize="xs"
                          />

                          {showDetails &&
                            transactionHasBonus &&
                            !transactionSubmitError && (
                              <div className="flex relative mt-10 items-center justify-center">
                                <TwToggleSwitch
                                  onClick={(e) => {
                                    setBonusConsent(e.target.checked);
                                  }}
                                  type="standard"
                                  checked={bonusConsent}
                                />
                                <p className="ml-5 max-w-64 text-left font-arboria-regular text-sand-800 text-sm">
                                  {i18n.t('transfer.consentMsg')}
                                  {selectedMerchant?.name}
                                </p>
                              </div>
                            )}
                        </InputWrapper>
                      )}
                    </AnimationWrapper>
                    {userHasPendingValidations ? (
                      <>
                        <WarningMessage
                          message={
                            identityValidationPending
                              ? i18n.t(
                                  'validationAlert.identityValidationPendingWithdrawal',
                                )
                              : i18n.t('validationAlert.waitConfirmation')
                          }
                          linkPath={
                            identityValidationPending &&
                            '/wallet/validate-identity'
                          }
                          linkLabel={
                            identityValidationPending &&
                            i18n.t('validationAlert.validateIdentity')
                          }
                        />
                        {!maxValue && !userHasPendingValidations && (
                          <TextButton
                            onClick={setShowInstructionModal}
                            margin="1rem 0"
                          >
                            {i18n.t('transactionError.increaseLimit')}
                          </TextButton>
                        )}
                      </>
                    ) : (
                      <>
                        {transactionSubmitError && !isMerchantLimitError ? (
                          <TextButton
                            onClick={setShowInstructionModal}
                            type="button"
                          >
                            {i18n.t('transactionError.increaseLimit')}
                          </TextButton>
                        ) : (
                          Boolean(
                            merchantHasNewIntegration ||
                              usernameOnMerchant ||
                              transactionData?.username,
                          ) && (
                            <ButtonWrapper>
                              <TransferButton
                                isValid={
                                  canTransferWithoutDetails ||
                                  canTransferWithDetailsAndBonus
                                }
                                consentMessage={
                                  showDetails &&
                                  isValid &&
                                  !bonusConsent &&
                                  transactionHasBonus
                                }
                                showDetails={showDetails}
                                currency={
                                  merchantCurrency ??
                                  selectedMerchant?.currencies?.[0] ??
                                  'BRL'
                                }
                              />
                            </ButtonWrapper>
                          )
                        )}
                      </>
                    )}
                  </FormStyled>
                );
              }}
            </Formik>
          )}
        </>
      ) : (
        <Loading marginTop="150px" />
      )}
      {limitsError && (
        <ErrorText>{i18n.t('error.userLimits.unspecific')}</ErrorText>
      )}
      <Modal
        showModal={showInstructionModal}
        setShowModal={setShowInstructionModal}
        height="290px"
        button={i18n.t('modal.understood')}
      >
        <LimitInstructions />
      </Modal>
    </>
  );
}

TransferForm.propTypes = {
  finalAmount: PropTypes.number,
  isFetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  merchant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  setShowDetails: PropTypes.func,
  setShowModal: PropTypes.func,
  showDetails: PropTypes.bool,
  usernameOnMerchant: PropTypes.string,
  setNewUsername: PropTypes.func,
}.isRequired;

export default TransferForm;
