import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import i18n from '@/translate/i18n';

import MainTemplate from '@/components/templates/MainTemplate';
import ReturnButton from '@/components/atoms/Buttons/ReturnButton';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import TwPageContentContainer from '@/components/v2/atoms/TwPageContentContainer';
import TwCopyPixKey from '@/components/v2/atoms/TwCopyPixKey';
import TwText from '@/components/v2/atoms/TwText';
import TwButton from '@/components/atoms/Buttons/TwButton';
import { getMyKeys } from '@/services/api';
import LoadingComponent from '@/components/molecules/LoadingComponent';
import EmptyKeys from '@/components/molecules/EmptyKeys';

const RETURN_TO = '/wallet';

function PixArea() {
  const history = useHistory();
  const { isMobileLayout } = useDeviceWidth();
  const [myKeys, setMyKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMyKeys = async () => {
    setLoading(true);
    try {
      const { data } = await getMyKeys();
      setMyKeys(data);
    } catch (error) {
      toast.error(
        i18n.t([
          `error.updatePassword.${error.messages}`,
          'error.updatePassword.unspecific',
        ]),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyKeys();
  }, []);

  return (
    <MainTemplate
      headerProps={{
        title: i18n.t('pixArea.titleMyKeys'),
        titleColor: 'white',
        returnTo: RETURN_TO,
      }}
    >
      <TwPageContentContainer classList="flex flex-col h-full md:h-auto">
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {!isMobileLayout && (
              <div className="w-fit ">
                <ReturnButton
                  handleClick={() => history.push(RETURN_TO)}
                  color="light"
                  title={i18n.t('returnButton.return')}
                  isRollback
                  position="relative"
                />
              </div>
            )}
            <TwCopyPixKey keyPix="teste keypix" typeKey="1" />

            {!myKeys.length ? (
              <EmptyKeys />
            ) : (
              <div className="md:mt-10 flex flex-col justify-center w-full items-center">
                <div className="flex flex-col gap-16">
                  <TwText
                    text={i18n.t('pixArea.titleMyKeys')}
                    classList="hidden md:block md:text-[32px] md:leading-10"
                  />
                  <TwText
                    text={i18n.t('pixArea.thisYourKeys')}
                    classList="w-64 mx-auto md:w-full md:text-2xl  "
                  />
                </div>
                <div className="mt-12 md:mt-16 flex flex-col">
                  <TwText
                    text={`1 ${i18n.t('pixArea.of')} ${myKeys.length} ${i18n.t(
                      'pixArea.keys',
                    )}`}
                    tag="p"
                    color="blue-secondary"
                    classList="w-fit mb-4 ml-7"
                  />
                  {myKeys.map((item) => (
                    <TwCopyPixKey
                      keyPix={item.pix_key}
                      typeKey={item.pix_key_type}
                    />
                  ))}
                </div>
                <TwButton
                  label="Nova chave aleatória"
                  classList="!w-80 mx-auto font-black absolute bottom-10 md:block md:mb-10"
                />
              </div>
            )}
          </>
        )}
      </TwPageContentContainer>
    </MainTemplate>
  );
}

export default PixArea;
