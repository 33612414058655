import React from 'react';

import TwText from '@/components/v2/atoms/TwText';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwTextButton from '@/components/atoms/Buttons/TwTextButton';
import LogoVPag from '@/assets/vpagWalletLogo.svg';
import i18n from '@/translate/i18n';
import { useUserData } from '@/context/userContext';
import { getFirstName } from '@/components/pages/WalletHome/helpers';

const StartDepositTour = ({ onClickContinueButton, onClickSkipButton }) => {
  const {
    userData: { name },
  } = useUserData();

  return (
    <div className="w-full flex flex-col mt-auto">
      <img
        src={LogoVPag}
        alt="logo vpag"
        className="block lg:hidden w-28 mx-auto mb-16"
      />
      <TwText
        text={getFirstName(name)}
        font="arboriaBold"
        classList="pb-5 !text-[45px] mx-auto lg:!text-6xl lg:leading-[79px]"
      />
      <div className="mx-8 flex flex-col gap-8 lg:gap-3">
        <TwText
          text={i18n.t('tours.tourDeposit.stepInitial.descriptionTitle')}
          font="arboriaBold"
          classList="text-xl leading-5 lg:text-2xl lg:leading-6"
        />
        <TwText
          text={i18n.t('tours.tourDeposit.stepInitial.description')}
          classList="text-lg leading-6"
        />
      </div>

      <div className="w-full mt-36 lg:max-w-[343px] lg:mx-auto ">
        <TwButton
          label={i18n.t('actions.start')}
          classList="!text-base leading-4 lg:leading-6 lg:!text-2xl"
          onClick={onClickContinueButton}
        />
        <TwTextButton
          label={i18n.t('actions.skip')}
          classList="!text-base lg:!text-xl lg:leading-6"
          onClick={onClickSkipButton}
        />
      </div>
    </div>
  );
};

export default StartDepositTour;
