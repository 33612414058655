import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  animation: goLeftDefault 1s;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  min-width: 220px;
`;

export const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  min-height: 60px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.font.size.large};
  margin-bottom: 3vh;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.medium};
    margin-bottom: 0;
  }
`;

export const SelectedBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.dark};
  border: none;
  border-radius: 40px;
  color: ${({ theme }) => theme.colors.light};
  display: flex;
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.secondary}`};
  height: 40px;
  justify-content: center;
  text-transform: uppercase;
  width: 150px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 10px;
  }
`;
