import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { Trans } from 'react-i18next';
import Loading from '@/components/atoms/Loading';
import Modal from '@/components/molecules/Modal';
import RecoverPassword from '@/components/molecules/Modal/Messages/RecoverPassword';
import { MAX_COOKIE_EXPIRE_DATE } from '@/helpers/constants';
import { SIGNIN_SCHEMA } from '@/helpers/schemas';
import i18n from '@/translate/i18n';
import useLogin from '@/hooks/useLogin';
import TwFormField from '@/components/atoms/Inputs/TwFormField';
import { Title } from '@/components/atoms/Typography/styles';
import TwSubmitButton from '@/components/atoms/Buttons/TwSubmitButton';

import { FormStyled, AnimationWrapper, Footer, ErrorMessage } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import TwTextButton from '@/components/atoms/Buttons/TwTextButton';

function SignInForm({ transactionId, invertAnimation }) {
  const { pathname } = useLocation();
  const { submitLogin, submitLoginForTransaction } = useLogin();
  const [cookies, setCookie] = useCookies();
  const history = useHistory();

  const isExternalLogin = pathname?.includes('/transactions/signin');

  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  if (!cookies?.guid) {
    setCookie('guid', uuidv4(), {
      expires: MAX_COOKIE_EXPIRE_DATE,
    });
  }

  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        height="400px"
        width="450px"
      >
        <RecoverPassword setShowModal={setShowModal} />
      </Modal>
      <Formik
        validationSchema={SIGNIN_SCHEMA}
        initialValues={{
          email: '',
          password: '',
        }}
        initialErrors={{ email: 'Required', password: 'Required' }}
        onSubmit={async ({ email, password }) => {
          const payload = { email, password, password_confirmation: password };

          if (isExternalLogin) {
            await submitLoginForTransaction(
              payload,
              transactionId,
              setErrorMessage,
              setLoginError,
            );
          } else {
            await submitLogin(payload, setErrorMessage, setLoginError);
          }
        }}
      >
        {({
          values,
          isValid,
          isSubmitting,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <FormStyled
            onChange={() => setLoginError(false)}
            $invertAnimation={invertAnimation}
          >
            {!isSubmitting ? (
              <>
                <AnimationWrapper gap="20px">
                  <div className="w-full max-w-[600px] px-10 md:px-16 flex flex-col gap-10">
                    <TwTitle size="xl" color="white" classList="text-center">
                      <Trans
                        i18nKey="signin.accessVpag"
                        i18n={i18n}
                        components={[
                          <Title className="text-grass-800 inline" />,
                        ]}
                      />
                    </TwTitle>
                    <section className="w-full flex flex-col gap-6">
                      <TwFormField
                        id="email"
                        name="email"
                        type="text"
                        handleBlur={() => setFieldTouched('email')}
                        value={values.email}
                        error={
                          (errors?.email &&
                            errors.email !== 'required' &&
                            touched?.email) ||
                          loginError
                        }
                        placeholder={i18n.t('signin.emailPlaceholder')}
                      />
                      <TwFormField
                        id="password"
                        name="password"
                        type="password"
                        value={values.password}
                        error={loginError}
                        placeholder={i18n.t('signin.passwordPlaceholder')}
                      />
                      <button
                        className="text-grass-800 underline font-arboria-regular text-sm"
                        type="button"
                        onClick={setShowModal}
                        data-testid="signin-forgot-password"
                        id="signin-forgot-password"
                      >
                        {i18n.t('signin.forgotPass')}
                      </button>
                    </section>
                    <TwSubmitButton
                      before={i18n.t('signin.buttonAfter')}
                      after={i18n.t('signin.buttonAfter')}
                      isValid={isValid}
                      submitError={loginError}
                      isSubmitting={isSubmitting}
                      data-testid="signin-submit-btn"
                    />
                  </div>
                </AnimationWrapper>
                <Footer>
                  {loginError && <ErrorMessage>{errorMessage}</ErrorMessage>}
                  <div className="pb-10 flex gap-2">
                    <p className="text-white text-base">
                      {i18n.t('signin.notRegisterYet')}
                    </p>
                    <TwTextButton
                      classList="font-arboria-regular text-grass-800 underline !text-base !w-fit"
                      to={isExternalLogin ? '/external/signup' : '/signup'}
                      family="primary"
                      data-testid="signin-signup-link"
                      label={i18n.t('signin.registerHere')}
                      onClick={() =>
                        history.push(
                          isExternalLogin ? '/external/signup' : '/signup',
                          { showCarroucelMobile: true },
                        )
                      }
                    />
                  </div>
                </Footer>
              </>
            ) : (
              <Loading />
            )}
          </FormStyled>
        )}
      </Formik>
    </>
  );
}

SignInForm.propTypes = {
  transactionId: PropTypes.string,
  invertAnimation: PropTypes.bool,
};

export default SignInForm;
