/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainTemplate from '@/components/templates/MainTemplate';
import i18n from '@/translate/i18n';
import { TABS } from '@/helpers/constants';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import NotificationItem from '@/components/v2/atoms/NotificationItem';
import TwText from '@/components/v2/atoms/TwText';
import { ReactComponent as EmptyNotification } from '@/assets/noNotification.svg';
import { useNotifications } from '@/hooks/useNotifications';
import NotificationModal from '@/components/molecules/Modal/Notifications';

const NUM_OF_PAGES_DEFAULT = 2;
const PER_PAGE = 7;

function NotificationsPage() {
  const {
    notificationsData,
    unreadNotifications,
    changeNotificationPage,
    getUserNotifications,
    getUnreadNotifications,
    updateNotificationReaded,
    updateNotificationReadedAll,
    NOTIFICATION_TYPES,
  } = useNotifications();

  const [activeTab, setActiveTab] = useState(TABS.ALL_TAB);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationModalContent, setNotificationModalContent] = useState({});

  const { isMobileLayout } = useDeviceWidth();
  const history = useHistory();
  const { search, pathname } = history.location;
  const searchParams = new URLSearchParams(search);

  const hasNotification = notificationsData.notifications.length;

  const handleChangePage = ({ selected }) => {
    changeNotificationPage({
      page: selected + 1,
      per_page: PER_PAGE,
      onlyNews: activeTab !== TABS.ALL_TAB,
    });
  };

  const setQueryParam = (key, value) => {
    searchParams.set(key, value);
    history.push({
      pathname,
      search: `?${searchParams.toString()}`,
    });
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  return (
    <MainTemplate
      headerProps={{
        returnTo: '/wallet',
        title: i18n.t('notification.title'),
      }}
    >
      <div className="size-full flex justify-around py-[5vh] px-4 sm:px-0">
        <div className="flex flex-col justify-start relative max-w-[600px] 2xl:max-w-none w-full lg:w-[80%] 2xl:w-[55%]">
          <div className="flex items-center justify-center">
            <TwTitle
              text={i18n.t('notification.title')}
              size={isMobileLayout ? 'm_lg' : 'd_lg'}
              font="arboriaBold"
              classList="text-center mb-16"
            />
          </div>
          <div
            className={`w-full lg:max-h-[50vh] 2xl:max-h-full overflow-y-auto flex-col justify-start flex ${
              !notificationsData.notifications.length
                ? 'justify-center'
                : 'justify-start'
            } flex-grow`}
            id="transactions-list"
          >
            <div className="flex justify-between items-center border-b border-shadow-400 p-[19px]">
              <div className="flex justify-start gap-2.5">
                <TwText
                  text={i18n.t('notification.allTab')}
                  color={activeTab === TABS.ALL_TAB ? 'white' : 'white/50'}
                  classList="hover:cursor-pointer"
                  onClick={() => {
                    setActiveTab(TABS.ALL_TAB);
                    setQueryParam('tab', TABS.ALL_TAB);
                    getUserNotifications();
                    getUnreadNotifications();
                  }}
                />
                <TwText
                  text={i18n.t('notification.notReadsTab', {
                    unreadQuantities: unreadNotifications,
                  })}
                  color={activeTab === TABS.UNREAD_TAB ? 'white' : 'white/50'}
                  classList="hover:cursor-pointer"
                  onClick={() => {
                    setActiveTab(TABS.UNREAD_TAB);
                    setQueryParam('tab', TABS.UNREAD_TAB);
                    getUserNotifications(true);
                    getUnreadNotifications();
                  }}
                />
              </div>
              {Boolean(unreadNotifications) && (
                <TwText
                  text={i18n.t('notification.markAllAsRead')}
                  color="blue-secondary"
                  classList="hover:cursor-pointer"
                  onClick={updateNotificationReadedAll}
                  size="sm"
                />
              )}
            </div>

            {!hasNotification ? (
              <div className="flex flex-col flex-grow items-center justify-center">
                <EmptyNotification className="h-14 w-auto" />
                <TwText
                  tag="p"
                  size="lg"
                  text={<Trans i18nKey="notification.noNotification" />}
                  classList="text-left mt-4"
                />
                <TwText
                  tag="p"
                  size="md"
                  text={<Trans i18nKey="notification.checkBackLater" />}
                  classList="text-left mt-2"
                />
              </div>
            ) : (
              notificationsData.notifications
                .slice(0, PER_PAGE)
                .filter(
                  (item) =>
                    activeTab === TABS.ALL_TAB ||
                    (activeTab === TABS.UNREAD_TAB && !item.read_at),
                )
                .map((item) => {
                  const type =
                    item?.type === NOTIFICATION_TYPES.affiliations
                      ? `${item?.type}_${item?.data?.status}`
                      : item?.type;

                  return (
                    <NotificationItem
                      notification={item}
                      type={type}
                      notificationTypes={NOTIFICATION_TYPES}
                      setShowNotification={setShowNotification}
                      setNotificationModalContent={setNotificationModalContent}
                      setReaded={() => {
                        if (!item.read_at) {
                          updateNotificationReaded(item.id);
                        }
                      }}
                    />
                  );
                })
            )}
          </div>
          {notificationsData.pageCount > 1 && (
            <div className="flex h-20 justify-center w-full text-grass-800">
              <ReactPaginate
                breakLabel=""
                nextLabel={<IoIosArrowForward />}
                previousLabel={<IoIosArrowBack />}
                disableInitialCallback
                forcePage={notificationsData.page - 1}
                pageCount={notificationsData.pageCount}
                onPageChange={handleChangePage}
                marginPagesDisplayed={1}
                pageRangeDisplayed={NUM_OF_PAGES_DEFAULT}
                activeLinkClassName="current-page !text-lg font-arboria-bold !opacity-100 px-3"
                disabledClassName="button-disabled opacity-50 cursor-not-allowed"
                pageLinkClassName="page-link text-sm opacity-60 px-3 hover:opacity-100"
                previousLinkClassName="prev-button flex text-lg opacity-60 px-4 hover:opacity-100"
                nextLinkClassName="next-button flex text-lg opacity-60 px-4 hover:opacity-100"
                containerClassName="flex items-center"
                pageClassName="cursor-pointer"
              />
            </div>
          )}
        </div>
      </div>
      {showNotification && (
        <NotificationModal
          closeModal={() => setShowNotification(false)}
          content={notificationModalContent}
        />
      )}
    </MainTemplate>
  );
}

export default NotificationsPage;
