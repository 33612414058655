import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';

import Tooltip from '@/components/atoms/Tooltip';
import i18n from '@/translate/i18n';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import { VPAG_STORE_URL } from '@/helpers/constants';

import {
  NavLinkWrapper,
  Text,
  TextWrapper,
  Wrapper,
  ArrowButton,
  Arrow,
} from '@/components/atoms/MenuModalStyle/styles';

import MenuItems from './navMenuItems';
import { useNotifications } from '@/hooks/useNotifications';

const MINIMUM_WALLETS_NUMBER_TO_SHOW = 2;

function NavMenu({ borders, arrows, isCollapsed }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { userData } = useUserData();

  const {
    walletData: { wallets },
  } = useWalletData(null);

  const { getUnreadNotifications } = useNotifications();

  const identityValidated = userData?.kyc_level > 0;
  const storeToken = userData?.store_token;
  const shouldHideConversionMenu =
    Object.keys(wallets).length < MINIMUM_WALLETS_NUMBER_TO_SHOW;

  const redirectTo = (newPathname, isExternalLink) => {
    if (isExternalLink) {
      return window.open(newPathname);
    }
    if (pathname !== newPathname) {
      return history.push(newPathname);
    }
    return null;
  };

  const checkPathname = (path) => pathname.split('/')[1] === path;

  const handleClick = (validationRequired, path, externalLink) => {
    getUnreadNotifications();
    if (!validationRequired || (validationRequired && identityValidated)) {
      redirectTo(path, externalLink);
    }
  };

  return (
    <Wrapper>
      {MenuItems.map(
        ({
          activeIcon: ActiveIcon,
          externalLink,
          hideArrows,
          hideBasedOnWalletNumber,
          icon: Icon,
          id,
          name,
          path,
          validationRequired,
        }) => {
          const isActive = checkPathname(path?.replace('/', ''));

          return hideBasedOnWalletNumber && shouldHideConversionMenu ? null : (
            <Tooltip
              content={name === 'store' ? i18n.t('topLeftMenu.store') : name}
              placement="right"
              disabled={!isCollapsed}
              key={id}
            >
              {name === 'store' ? (
                <NavLinkWrapper
                  borders={borders}
                  isCollapsed={isCollapsed}
                  onClick={() => {
                    getUnreadNotifications();
                    redirectTo(
                      storeToken
                        ? `${VPAG_STORE_URL}${storeToken}`
                        : VPAG_STORE_URL,
                      true,
                    );
                  }}
                >
                  <Icon />
                  <TextWrapper isCollapsed={isCollapsed}>
                    <Text>{i18n.t('topLeftMenu.store')}</Text>
                  </TextWrapper>
                  {arrows && (
                    <ArrowButton>
                      <Arrow />
                    </ArrowButton>
                  )}
                </NavLinkWrapper>
              ) : (
                <NavLinkWrapper
                  id={id}
                  borders={borders}
                  highlight={isActive}
                  onClick={() =>
                    handleClick(validationRequired, path, externalLink)
                  }
                  disabled={validationRequired && !identityValidated}
                  isCollapsed={isCollapsed}
                >
                  {isActive ? <ActiveIcon /> : <Icon />}
                  <div
                    className={`${
                      isCollapsed ? 'hidden' : 'flex flex-col'
                    } ml-5`}
                  >
                    <span
                      className={`${
                        isActive
                          ? 'font-arboria-bold text-white'
                          : 'font-arboria-regular text-[#a9a9a9]'
                      } text-sm`}
                    >
                      {name}
                    </span>
                  </div>
                  {arrows && !hideArrows && (
                    <ArrowButton>
                      <Arrow />
                    </ArrowButton>
                  )}
                </NavLinkWrapper>
              )}
            </Tooltip>
          );
        },
      )}
    </Wrapper>
  );
}

NavMenu.propTypes = {
  arrows: PropTypes.bool,
  borders: PropTypes.bool,
  isCollapsed: PropTypes.bool,
}.isRequired;

export default NavMenu;
