import styled, { css } from 'styled-components';
import { ReactComponent as SimpleArrow } from '@/assets/simpleArrow.svg';

export const AbsoluteContainer = styled.div`
  ${({ theme, left = '15px', top = '26px', position = 'absolute' }) => css`
    align-items: center;
    color: ${theme.colors.info.title};
    cursor: pointer;
    display: flex;
    font: ${theme.font.size.base} ${theme.font.family.secondary};
    gap: 5px;
    left: ${left};
    position: ${position};
    z-index: 9;
    padding: 0 10px 10px;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      top: ${top};
    }
  `}
`;

export const Container = styled.div`
  ${({ theme, color = 'dark' }) => css`
    align-items: center;
    background-color: ${theme.colors.bg.darkSecondary};
    border-radius: 50%;
    border: none;
    color: ${theme.colors[color]};
    display: flex;
    justify-content: center;
    min-height: 24px;
    transform: rotate(180deg);
    transition: 0.3s transform ease;
    width: 24px;
    z-index: 9;
  `}
`;

export const ReturnIcon = styled(SimpleArrow)`
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.light};
  }
`;
