/* eslint-disable react/no-danger */
import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TwText from './TwText';
import { ReactComponent as Email } from '@/assets/email.svg';
import { ReactComponent as EmailSeen } from '@/assets/emailSeen.svg';
import { formatShortDate } from '@/helpers/stringFormat';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { ReactComponent as WhiteArrow } from '@/assets/whiteArrow.svg';

const SITE_NAME = 'vpag.com';

function NotificationItem({
  notification,
  type,
  setReaded,
  notificationTypes,
  setShowNotification,
  setNotificationModalContent,
}) {
  const createdAt = notification?.created_at;
  const title = notification?.data?.subject;
  const description = notification?.data?.description;
  const isReaded = notification?.read_at;
  const amount = notification?.data?.amount || 0;
  const userName = notification?.data?.username || '';
  const merchant = notification?.data?.merchant_name || '';

  const isNotificationPage = window.location.href.includes('notifications');
  const { isMobileLayout } = useDeviceWidth();
  const history = useHistory();

  const handleNotificationModal = (noti) => {
    setNotificationModalContent(noti);
    setShowNotification(true);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setReaded();
    if (notification?.data?.transactionId) {
      const actualPage = window.location.pathname;
      history.push({
        pathname: `/transactions/${notification.data.transactionId}`,
        from: actualPage,
      });
    }
    if (notification?.type === notificationTypes.admin) {
      if (notification?.data?.redirect_link) {
        const isExternal =
          !notification?.data?.redirect_link?.includes(SITE_NAME);
        if (isExternal) {
          window.open(notification.data.redirect_link, '_blank');
        } else {
          const actualPage = window.location.pathname;
          const path = notification.data.redirect_link.replace(
            /^https?:\/\/[^/]+/,
            '',
          );
          history.push({
            pathname: path,
            from: actualPage,
          });
        }
      } else {
        handleNotificationModal(notification?.data);
      }
    }
    if (notification?.type === 'affiliation') {
      const actualPage = window.location.pathname;
      history.push({
        pathname: `/club-vplus`,
        from: actualPage,
      });
    }
    if (notification?.type === notificationTypes.identityPending) {
      const actualPage = window.location.pathname;
      history.push({
        pathname: `/wallet`,
        from: actualPage,
      });
    }
    if (notification?.type === notificationTypes.validationApproved) {
      const actualPage = window.location.pathname;
      history.push({
        pathname: `/deposit`,
        from: actualPage,
      });
    }
  };

  return (
    <button
      type="button"
      className="list-row w-full hover:cursor-pointer px-6 py-3 border-b border-shadow-500 last:border-b-0"
      onClick={handleClick}
    >
      <div className="flex items-center">
        <div className="flex mr-6 items-center justify-center h-[41px] aspect-square rounded-full bg-shadow-900">
          {isReaded ? <EmailSeen /> : <Email />}
        </div>
        <div
          className={`w-full flex justify-between items-center ${
            isMobileLayout && 'flex-col !items-start'
          } ${!isNotificationPage && 'flex-col !items-start'}`}
        >
          {type === 'admin' ? (
            <div
              className={`${
                isNotificationPage && !isMobileLayout
                  ? 'max-w-[75%]'
                  : 'max-w-[92%]'
              }`}
            >
              <TwText
                tag="p"
                size="sm"
                text={title}
                classList={`text-left font-bold ${
                  isReaded ? 'text-white/50' : 'text-white'
                }`}
              />
              <div
                className={`custom-text-xs !text-xs max-h-8 overflow-hidden text-left ${
                  isReaded ? 'text-white/50' : 'text-white'
                }`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          ) : (
            <div>
              <TwText
                tag="p"
                size="sm"
                text={
                  <Trans
                    i18nKey={`notification.types.${type}`}
                    values={{ userName }}
                  />
                }
                classList={`text-left font-bold ${
                  isReaded ? 'text-white/50' : 'text-white'
                }`}
              />
              <TwText
                tag="p"
                size="xs"
                text={
                  <Trans
                    i18nKey={`notification.messages.${type}`}
                    values={{ amount, merchant }}
                  />
                }
                classList={`max-h-8 overflow-hidden text-left ${
                  isReaded ? 'text-white/50' : 'text-white'
                }`}
              />
            </div>
          )}
          <TwText
            tag="p"
            size="sm"
            text={formatShortDate(createdAt, true)}
            classList="text-right text-white/50"
          />
        </div>
        {isMobileLayout && <WhiteArrow />}
      </div>
    </button>
  );
}

export default NotificationItem;
