import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import BalanceWithCurrencySwitch from '@/components/molecules/BalanceWithCurrencySwitch';
import PointsSummary from '@/components/molecules/PointsSummary';
import ReturnButton from '@/components/atoms/Buttons/ReturnButton';
import TopBar from '@/components/molecules/TopBar';
import UserInfo from '@/components/atoms/UserInfo';
import { useWalletData } from '@/context/walletContext';
import { formatCurrency } from '@/helpers/stringFormat';
import useUpdateUser from '@/hooks/useUpdateUser';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import i18n from '@/translate/i18n';

import {
  ChildrenWrapper,
  Container,
  Content,
  CustomButtonWrapper,
  FloatingButton,
  ReturnWrapper,
  ReverseWrapper,
  Title,
} from './style';
import NotificationsButton from '@/components/v2/atoms/NotificationsButton';
import { useNotifications } from '@/hooks/useNotifications';

function WalletHeader({
  amountHighlighted,
  children,
  customButton,
  externalVersion,
  hideInDesktop,
  mobileFullVersion,
  returnAction,
  returnTo,
  showAvailableBalance,
  subheading,
  title,
  titleColor,
  background,
}) {
  const history = useHistory();
  const { pathname } = history.location;
  const {
    walletBalance,
    walletData: { selectedWallet, shouldRenderFirstDepositBtn },
  } = useWalletData();
  useUpdateUser();
  const { unreadNotifications } = useNotifications();

  const { isMobileLayout } = useDeviceWidth();
  const isNotificationPage = window.location.href.includes('notifications');

  const isHomePage = pathname === '/wallet';
  return (
    <Container
      mobileFullVersion={
        mobileFullVersion && !pathname.includes('validate-identity')
      }
      hideInDesktop={hideInDesktop}
      background={background}
    >
      <TopBar showInMobile={mobileFullVersion} />
      <Content className="relative mt-8 lg:mt-0 lg:static">
        {(returnAction || (returnTo && isMobileLayout)) && (
          <ReturnButton
            top="105px"
            left="20px"
            handleClick={
              !isMobileLayout && returnAction
                ? returnAction
                : () => history.push(returnTo)
            }
            color="light"
            isRollback={!!returnAction && !isMobileLayout}
            title={i18n.t('returnButton.return')}
            className="top-0 cursor-pointer lg:top-[105px]"
          />
        )}
        <ReturnWrapper
          amountHighlighted={amountHighlighted}
          hideInMobile={mobileFullVersion}
        >
          <div>
            <Title titleColor={titleColor}>{title}</Title>
            {subheading && <p>{subheading}</p>}
            {showAvailableBalance && walletBalance ? (
              <p>
                {i18n.t('topBar.availableBalance')}{' '}
                <span className="!text-grass-800">
                  {formatCurrency(walletBalance, selectedWallet)}
                </span>
              </p>
            ) : null}
          </div>
        </ReturnWrapper>
        {customButton && (
          <CustomButtonWrapper>{customButton}</CustomButtonWrapper>
        )}
        {(!pathname.includes('validate-identity') || !isMobileLayout) && (
          <ReverseWrapper mobileFullVersion={mobileFullVersion}>
            <PointsSummary hideInMobile={!mobileFullVersion} />
            <BalanceWithCurrencySwitch />
          </ReverseWrapper>
        )}
        {shouldRenderFirstDepositBtn && (
          <FloatingButton to="/deposit" showInMobileMode={isHomePage}>
            {i18n.t('topBar.firstDepositBtn')}
          </FloatingButton>
        )}
        <ChildrenWrapper>{children}</ChildrenWrapper>
        {!isMobileLayout && false && (
          <NotificationsButton
            disabled={isNotificationPage}
            unread={unreadNotifications}
          />
        )}
        {!externalVersion && <UserInfo />}
      </Content>
    </Container>
  );
}

WalletHeader.propTypes = {
  amountHighlighted: PropTypes.bool,
  children: PropTypes.element,
  customButton: PropTypes.element,
  externalVersion: PropTypes.bool,
  hideInDesktop: PropTypes.bool,
  mobileFullVersion: PropTypes.bool,
  returnAction: PropTypes.func,
  returnTo: PropTypes.string,
  showAvailableBalance: PropTypes.bool,
  subheading: PropTypes.string,
  title: PropTypes.string,
  background: PropTypes.string,
  titleColor: PropTypes.string,
};

export default WalletHeader;
