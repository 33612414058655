import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import BonusListItem from '@/components/molecules/BonusListItem';
import BonusesAndPromotionsIcon from '@/components/molecules/BonusesAndPromotionsIcon';
import i18n from '@/translate/i18n';
import useAllMerchants from '@/hooks/useAllMerchants';
import { useUserData } from '@/context/userContext';

import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';

function BonusList({ selectedBonus, setSelectedBonus, setShowModal }) {
  const {
    userData: { bonuses },
  } = useUserData();

  const { getAllMerchants, isFetching } = useAllMerchants();

  useEffect(() => {
    const controller = new AbortController();
    const merchantList = JSON.parse(localStorage.getItem('merchants'));

    if (!merchantList && !isFetching) {
      getAllMerchants();
    }

    return () => controller.abort();
  }, []);

  return (
    <section className="size-full flex flex-col justify-between md:justify-around items-center max-h-[50vh]">
      <BonusesAndPromotionsIcon type="bonuses" />
      <div
        className="flex flex-col items-center text-sm overflow-auto w-[85%] md:w-full max-h-[50vh]  md:max-h-[50vh] md:pr-4 pb-20 md:pl-8 md:p-0"
        id="bonus-list"
      >
        {bonuses?.length ? (
          bonuses?.map((bonus) => (
            <BonusListItem
              showTransactionType
              key={bonus.id}
              bonus={bonus}
              selectedBonus={selectedBonus}
              handleClick={setSelectedBonus}
              setShowModal={setShowModal}
            />
          ))
        ) : (
          <p className="text-white">
            {i18n.t('bonusesAndPromotions.noBonusFound')}
          </p>
        )}
        <LinkTextButton
          className="pt-5 !text-lg"
          to="/bonuses-and-promotions/history"
        >
          {i18n.t('bonus.showHistory')}
        </LinkTextButton>
      </div>
      <span />
    </section>
  );
}

BonusList.propTypes = {
  selectedBonus: PropTypes.shape,
  setSelectedBonus: PropTypes.func,
}.isRequired;

export default BonusList;
