import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  align-items: center;
  animation: goLeftDefault 0.8s;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
`;

export const ConsentText = styled.p`
  ${({ theme }) => css`
    font: ${theme.font.size.small} ${theme.font.family.primary};
    display: inline;
    text-align: left;
    text-wrap: balance;
    white-space: initial !important;

    button {
      display: inline;
    }
  `}
`;

export const CheckboxWrapper = styled.div`
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
  flex-direction: column;
  margin: 2vh 0;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
`;

export const Loader = styled.div`
  height: ${({ isExternal }) => (isExternal ? '100%' : '85%')};
  position: absolute;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  bottom: 0;
`;
