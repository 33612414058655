import React from 'react';
import { formatCurrency } from '@/helpers/stringFormat';
import i18n from '@/translate/i18n';
import { useWalletData } from '@/context/walletContext';

import { Text } from './style';
import useBalance from '@/hooks/useBalance';

function CurrentBalance() {
  const {
    walletBalance,
    walletData: { selectedWallet },
  } = useWalletData();

  useBalance();

  return (
    <Text className="text-base">
      {walletBalance && (
        <>
          {i18n.t('topBar.availableBalance')}{' '}
          <span className="font-extrabold">
            {formatCurrency(walletBalance, selectedWallet)}
          </span>
        </>
      )}
    </Text>
  );
}

export default CurrentBalance;
