import styled from 'styled-components';

import { Button as ButtonStyle } from '@/components/atoms/Buttons/Button/styles';

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.info.light};
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.primary}`};
  margin-bottom: 5vh;
`;

export const AccountWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.info.gray};
  display: flex;
  font-size: ${({ theme }) => theme.font.size.xSmall};
  height: 67px;
  max-width: 768px;
  width: 82vw;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    border: none;
    justify-content: flex-start;
    margin-left: 60px;
    width: 200px;
  }
`;

export const Strong = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font: ${({ theme }) =>
    `${theme.font.size.xSmall} ${theme.font.family.secondary}`};
  transition: 0.5s all ease-in-out;
`;

export const ActionButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  max-width: 300px;
  width: 82vw;

  div {
    display: flex;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-self: start;
  }
`;

export const Button = styled(ButtonStyle)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-self: flex-end;
  }
`;
