import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MdRefresh } from 'react-icons/md';

import useBalance from '@/hooks/useBalance';
import { useWalletData } from '@/context/walletContext';
import i18n from '@/translate/i18n';
import {
  formatCurrencyWithoutSymbol,
  getCurrencySymbol,
} from '@/helpers/stringFormat';

import { useUserData } from '@/context/userContext';
import TwText from '@/components/v2/atoms/TwText';
import { kycValidationSlugs } from '@/helpers/constants';

const MINIMUM_WALLETS_NUMBER_TO_SHOW = 2;
const ROUTES_THAT_SHOULD_REFRESH_BALANCE = [
  '/deposit',
  '/transfer',
  '/withdrawal',
  '/conversion',
  '/transfer-api',
  '/cashback/merchants',
];
const WAITING_VALIDATION = 2;
const STATUS_FAILED = 3;
const STATUS_PROCESSING = 4;
const STATUS_COMPLETE = 5;
const STATUS_BLOCKED = 6;

function BalanceWithCurrencySwitch() {
  const { pathname } = useLocation();
  const { isFetching, error, getBalance, setError } = useBalance();
  const { userData } = useUserData();
  const {
    walletBalance,
    setWalletData,
    walletData: { selectedWallet, wallets },
  } = useWalletData();
  const initialStatus = userData?.status === WAITING_VALIDATION;
  const pendingStatus = userData?.status === STATUS_FAILED;
  const blockedStatus =
    userData?.status === STATUS_FAILED &&
    kycValidationSlugs.revalidate.some((str) =>
      userData?.details?.caf_callback_error?.includes(str),
    );
  const processingStatus = userData?.status === STATUS_PROCESSING;
  const rejectedStatus =
    kycValidationSlugs.block.some((str) =>
      userData?.details?.caf_callback_error?.includes(str),
    ) || userData?.status === STATUS_BLOCKED;
  const balanceError = walletBalance ?? 'error';
  const shouldShowWalletSelector =
    Object.keys(wallets).length >= MINIMUM_WALLETS_NUMBER_TO_SHOW;

  const switchWallet = () => {
    const walletArray = Object.keys(wallets);
    const currIndex = walletArray.indexOf(selectedWallet);
    const newIndex = currIndex + 1;

    if (walletArray[newIndex]) {
      setWalletData((prevState) => ({
        ...prevState,
        selectedWallet: walletArray[newIndex],
      }));
    } else {
      setWalletData((prevState) => ({
        ...prevState,
        selectedWallet: walletArray[0],
      }));
    }
  };

  useEffect(() => {
    if (ROUTES_THAT_SHOULD_REFRESH_BALANCE.includes(pathname)) {
      getBalance();
    }
  }, [pathname, getBalance]);

  return (
    <section className="flex flex-col items-start md:gap-2 sm:inline">
      <div className="flex items-center gap-2">
        {userData?.status === STATUS_COMPLETE ? (
          <TwText
            text={i18n.t('topBar.availableBalance')}
            inlineSize="text-xs"
            font="arboriaBold"
          />
        ) : (
          <TwText
            color="grass-800"
            font="arboriaBold"
            text="Status da Conta:"
          />
        )}
        {shouldShowWalletSelector &&
          Object.keys(wallets).map((item) => (
            <button
              className={`cursor-pointer text-[14px] font-['Arboria_Bold'] ${
                selectedWallet === item
                  ? 'border-b border-grass-800 text-white'
                  : 'text-[#e1d9cd]'
              }`}
              key={item}
              type="button"
              onClick={() => {
                localStorage.setItem('wallet', item);
                setWalletData((prevState) => ({
                  ...prevState,
                  selectedWallet: item,
                }));
              }}
            >
              {item}
            </button>
          ))}
      </div>
      {isFetching && balanceError === 'error' ? (
        <MdRefresh className="text-primary text-[22px] ml-1 animate-spin" />
      ) : !error ? (
        <div
          role="button"
          onKeyDown={switchWallet}
          onClick={switchWallet}
          tabIndex={0}
        >
          <h1
            className={`text-primary cursor-pointer text-3xl font-primary select-none ${
              !walletBalance ? 'text-info-primary/75' : 'text-grass-800'
            }`}
          >
            {userData?.status === STATUS_COMPLETE ? (
              <>
                {getCurrencySymbol('pt-BR', selectedWallet)}
                <span className="ml-1">
                  {formatCurrencyWithoutSymbol(
                    walletBalance || 0,
                    selectedWallet,
                    true,
                  )}
                </span>
              </>
            ) : initialStatus ? (
              <TwText inlineSize="text-3xl" text={i18n.t('header.pending')} />
            ) : rejectedStatus ? (
              <TwText
                color="cherryRed"
                inlineSize="text-xl md:text-3xl"
                text={i18n.t('header.rejected')}
              />
            ) : blockedStatus ? (
              <TwText
                color="cherryRed"
                inlineSize="text-xl md:text-3xl"
                text={i18n.t('header.validationError')}
              />
            ) : pendingStatus ? (
              <TwText
                inlineSize="text-xl md:text-3xl"
                classList="!text-[#80BCFF]"
                text={i18n.t('header.validationPending')}
              />
            ) : (
              processingStatus && (
                <TwText
                  inlineSize="text-xl md:text-3xl"
                  text={i18n.t('header.processing')}
                />
              )
            )}
          </h1>
        </div>
      ) : (
        <div className="flex items-center justify-centertext-grass-600 my-[12%] sm:my-0">
          <p>{i18n.t('error.balance.default')}</p>
          <button
            className="bg-transparent border-none"
            type="button"
            disabled={isFetching}
            onClick={() => {
              setError('');
              getBalance();
            }}
          >
            <MdRefresh className="text-primary text-[22px] ml-1 animate-spin" />
          </button>
        </div>
      )}
    </section>
  );
}

export default BalanceWithCurrencySwitch;
