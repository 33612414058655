import React, { useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwTitle from '@/components/atoms/TwTitle';
import Deposit from '@/assets/deposit.gif';
import Store from '@/assets/store.gif';
import Transfer from '@/assets/transfer.gif';
import i18n from '@/translate/i18n';

import './swipper-customer.css';
import 'swiper/css';

const SPACE_BETWEEN = 50;
const SLIDES_PER_VIEW = 1;
const ZERO = 0;
const ONE = 1;
const TWO = 2;

const CONTENT_SLIDES = {
  mobile: {
    [ZERO]: {
      title: 'tutorial.onboarding.mobile.deposit.title',
      text: 'tutorial.onboarding.mobile.deposit.text',
    },
    [ONE]: {
      title: 'tutorial.onboarding.mobile.transfer.title',
      text: 'tutorial.onboarding.mobile.transfer.text',
    },
    [TWO]: {
      title: 'tutorial.onboarding.mobile.changePoints.title',
      text: 'tutorial.onboarding.mobile.changePoints.text',
    },
  },
  desktop: {
    [ZERO]: {
      title: 'tutorial.onboarding.desktop.deposit.title',
      secondTitle: 'tutorial.onboarding.desktop.deposit.secondTitle',
      text: 'tutorial.onboarding.desktop.deposit.text',
    },
    [ONE]: {
      title: 'tutorial.onboarding.desktop.transfer.title',
      secondTitle: 'tutorial.onboarding.desktop.transfer.secondTitle',
      text: 'tutorial.onboarding.desktop.transfer.text',
    },
    [TWO]: {
      title: 'tutorial.onboarding.desktop.changePoints.title',
      secondTitle: 'tutorial.onboarding.desktop.changePoints.secondTitle',
      text: 'tutorial.onboarding.desktop.changePoints.text',
    },
  },
};

const tutorialSlide = {
  deposit: Deposit,
  transfer: Transfer,
  store: Store,
};

const STATE = {
  [ZERO]: 'deposit',
  [ONE]: 'transfer',
  [TWO]: 'store',
};

export const ModalTutorialOnboarding = ({ onClose, fitContent }) => {
  const swiperRefMobile = useRef(null);
  const swiperRefDesktop = useRef(null);
  const [currentSlideMobile, setCurrentSlideMobile] = useState(ZERO);
  const [currentSlideDesktop, setCurrentSlideDesktop] = useState(ZERO);

  const isLastStepMobile = useMemo(
    () => currentSlideMobile === TWO,
    [currentSlideMobile],
  );

  const isLastStepDesktop = useMemo(
    () => currentSlideDesktop === TWO,
    [currentSlideDesktop],
  );

  const handleNextStepDesktop = () => {
    if (isLastStepDesktop) {
      onClose();
      return;
    }
    swiperRefDesktop.current.slideNext();
  };

  const handleNextStepMobile = () => {
    if (isLastStepMobile) {
      onClose();
      return;
    }
    swiperRefMobile.current.slideNext();
  };

  const SwiperSlideDesktopCustom = ({ currentItem }) => (
    <div className="flex flex-col gap-2 text-left">
      <div className="leading-[3.5rem]">
        <TwTitle
          text={i18n.t(CONTENT_SLIDES.desktop[currentItem].title)}
          size="lg"
          className="text-[#fff]"
        />
        <TwTitle
          text={i18n.t(CONTENT_SLIDES.desktop[currentItem].secondTitle)}
          size="lg"
          color="grass-800"
        />
      </div>
      <p className="text-[1.375rem] text-white">
        {i18n.t(CONTENT_SLIDES.desktop[currentItem].text)}
      </p>
    </div>
  );

  return (
    <div
      className={`flex flex-col h-full ${
        fitContent ? 'justify-end' : ''
      } md:flex-row gap-y-2 md:gap-x-20 md:justify-center text-center`}
    >
      <Swiper
        onSwiper={(swiper) => {
          swiperRefMobile.current = swiper;
        }}
        spaceBetween={SPACE_BETWEEN}
        slidesPerView={SLIDES_PER_VIEW}
        onSlideChange={(swiper) => setCurrentSlideMobile(swiper.activeIndex)}
        allowTouchMove
        pagination={{
          clickable: false,
        }}
        className={`${
          fitContent ? 'w-[250px] iphone-se:w-[180px]' : 'w-[190px]'
        } h-fit px-auto p-auto md:hidden`}
      >
        <SwiperSlide>
          <img src={Deposit} alt="tutorial deposit" className="mb-10 md:h-80" />
        </SwiperSlide>
        <SwiperSlide className="md:h-full">
          <img
            src={Transfer}
            alt="tutorial transfer"
            className="mb-10 md:h-80"
          />
        </SwiperSlide>
        <SwiperSlide className="md:h-full">
          <img src={Store} alt="tutorial store" className="mb-10 md:h-80" />
        </SwiperSlide>
      </Swiper>
      <div className="flex flex-col md:hidden mb-10">
        <div className="px-3 flex flex-col gap-y-3">
          <div className={fitContent ? 'px-16 pb-4' : ''}>
            <TwTitle
              text={i18n.t(CONTENT_SLIDES.mobile[currentSlideMobile].title)}
              classList="!text-crem !min-h-[72px] leading-8"
              size="xl"
              font="arboriaBold"
            />
            <p className="text-crem text-[0.875rem]">
              {i18n.t(CONTENT_SLIDES.mobile[currentSlideMobile].text)}
            </p>
          </div>
          <div className={fitContent ? 'px-6' : null}>
            <TwButton
              label={i18n.t(
                isLastStepMobile ? 'actions.finished' : 'actions.next',
              )}
              classList="text-[1rem] mt-1"
              onClick={handleNextStepMobile}
            />
          </div>
        </div>
      </div>
      <div className="w-5/12  hidden md:block">
        <div className=" md:mt-16">
          <Swiper
            spaceBetween={SPACE_BETWEEN}
            slidesPerView={SLIDES_PER_VIEW}
            allowTouchMove={false}
            onSlideChange={(swiper) =>
              setCurrentSlideDesktop(swiper.activeIndex)
            }
            onSwiper={(swiper) => {
              swiperRefDesktop.current = swiper;
            }}
            pagination={{
              clickable: false,
            }}
            className=" hidden  h-full md:h-72  md:block"
          >
            <SwiperSlide>
              <SwiperSlideDesktopCustom currentItem={ZERO} />
            </SwiperSlide>
            <SwiperSlide>
              <SwiperSlideDesktopCustom currentItem={ONE} />
            </SwiperSlide>
            <SwiperSlide>
              <SwiperSlideDesktopCustom currentItem={TWO} />
            </SwiperSlide>
          </Swiper>
          <TwButton
            label={i18n.t(
              isLastStepDesktop ? 'actions.finished' : 'actions.next',
            )}
            className="text-black"
            classList="mt-5"
            style={{ color: 'black' }}
            onClick={handleNextStepDesktop}
          />
        </div>
      </div>
      <div className="hidden md:block  h-full">
        <img
          src={tutorialSlide[STATE[currentSlideDesktop]]}
          alt="tutorial deposit"
          className="h-[486px]"
        />
      </div>
    </div>
  );
};
