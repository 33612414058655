import React from 'react';
import PropTypes from 'prop-types';
import { ParagraphStyled, OptionCashBackContainer } from './style';

const SIZE_ICON = '44px';

function OptionCashback({ isVpag = false, srcIcon, textBody, onClick }) {
  return (
    <section
      className="flex flex-col relative"
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
      onClick={onClick}
    >
      {isVpag && (
        <div className="flex justify-end -mt-4 absolute w-[156px] z-10">
          <div className="bg-white rounded-[3px] text-[#191a1b] p-[5px] w-fit">
            Mais escolhido
          </div>
        </div>
      )}
      <OptionCashBackContainer vpag={isVpag}>
        <img
          src={srcIcon}
          alt={`icon ${textBody}`}
          width={SIZE_ICON}
          height={SIZE_ICON}
        />
        <ParagraphStyled size="small">{textBody}</ParagraphStyled>
      </OptionCashBackContainer>
    </section>
  );
}

OptionCashback.propTypes = {
  isVpag: PropTypes.bool,
  srcIcon: PropTypes.string,
  textBody: PropTypes.string,
}.isRequired;
export default OptionCashback;
